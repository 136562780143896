<template>
  <div class="AccessGuide document-content">
    <div class="docHeader">
      <h2>接入指导</h2>
      <div class="updateTime">发布时间：2022-01-21 9:00</div>
    </div>
    <AccessGuide class="markdown-body"></AccessGuide>
  </div>
</template>

<script setup>
import AccessGuide from "./mdDocument/接入方式.md";
export default {
  data() {
    return {};
  },
  components: {
    AccessGuide,
  },
};
</script>

<style lang="less" scoped>
.AccessGuide {
}
</style>